import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

function EventContent(props) {

    const [loaded, setloaded] = useState(false);
    const [width, setwidth] = useState(0);
    const [height, setheight] = useState(0);
    const [applynow, setapplynow] = useState(false)


    useEffect(() => {
        setwidth(window.innerWidth);
        setheight(window.innerHeight);

        if (width > 0) {
            setloaded(true);
        }
    })


    return (
        <div>
            {
                loaded ?
                    <Container>
                                <div style={{display: 'flex', flexDirection: width >1000 ? 'row' : 'column'}}>
                                    <LeftContainerLarge>
                                        <ImageContainer src={"/spec/tbcontrol/" + props.event.flyer} />
                                    </LeftContainerLarge>
                                    <RightContainerLarge>
                                        <Title dangerouslySetInnerHTML={ {__html: props.event.title}}></Title>
                                        <Description dangerouslySetInnerHTML={ {__html: props.event.description}}></Description>
                                        <PricingContainer>
                                            <h3>Price :</h3>
                                            {
                                                props.event.cost === "free"?
                                                <h5>Free</h5>
                                                :
                                                <div>
                                                    <h5>{props.event.costDescription}</h5>
                                                    {
                                                        props.event.pricing.map(p => {
                                                            return(
                                                                <div style={{display: 'flex', alignItems: 'center', margin: 2}}>
                                                                    <input type="radio" /><div>{p.price + " - " + p.specification}</div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            }
                                        </PricingContainer>
                                        <ApplyForm>
                                        <img onClick={() => setapplynow(!applynow)} style={{width: 200}} src={require('../images/applynow.png')} />
                                            {
                                                applynow?
                                                
                                                <Modal onClick={(e) => {
                                                    if(e.target === e.currentTarget){
                                                        setapplynow(false);
                                                    }
                                                }}>
                                                    <ModalContent>
                                                        <CloseButton onClick={() => setapplynow(false)} aria-label="Close Apply Form">&times;</CloseButton>
                                                        <iframe src="https://framaforms.org/course-take-back-control-of-your-digital-life-1574932383" width="100%" height="800" border="0" ></iframe>
                                                    </ModalContent>
                                                </Modal>
                                                :
                                                null
                                            }
                                        </ApplyForm>
                                    </RightContainerLarge>
                                </div>

                    </Container>
                    :
                    <div>Loading</div>
            }
        </div>
    )
}

const Container = styled.div`
    flex: 1;
    padding: 30px;
`;
const LeftContainerLarge = styled.div`
    flex: .5;
    text-align: center;
`;

const RightContainerLarge = styled.div`
    flex: 1;
    text-align: center;
`;

const ImageContainer = styled.img`
    max-width: 100%;
    height: auto;
`;

const Title = styled.h1`
`;

const Description = styled.h3`
`;
const PricingContainer = styled.div`
    margin-left: 18%;
    margin-top: 30px;
    text-align: start;
`;

const ApplyForm = styled.div`
    margin-top: 50px;
`;


const Modal = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    transition: all 0.3s linear;
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: auto;
    padding: 23px;
    border-radius: 4px;
    max-width: 60%;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;

`;

export default EventContent;