import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './App.css';
import Events from './pages/Events';

function App() {

  const [config, setConfig] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [menuSelected, setMenuSelected] = useState(null);
  const [activeContent, setActiveContent] = useState(null);
  const [width, setwidth] = useState(null);
  const [height, setheight] = useState(null);
  const [mobile, setMobile] = useState(false);
  const [event_Selected, setEvent_Selected] = useState(false);


  useEffect(() => {

    fetch('./spec/config.json?version=pre01-20191210')
      .then((r) => r.json())
      .then((data) => setConfig(data));


      setwidth(window.innerWidth);
      setheight(window.innerHeight);
  
      if (width > 0) {
          setloaded(true);
      }
  

  }, [])



  useEffect(() => {
    if (config !== null && width > 0) {
      console.log(config);
      if (width < 1000 && width != null) {
        setMobile(true)
    }
      setMenuSelected(config.menu[0].name);
      setloaded(true);
    }
  }, [config])


  useEffect(() => {
    if(loaded){
    config.menu.map(m => {
      if (m.name === menuSelected) {
        return (
          setActiveContent(get_Component(m.template))
        )
      }
    })
  }
  }, [menuSelected])



  const get_Component = (page) => {
    switch (page) {
      case "Events":
        return () => <Events set_Selected={setEvent_Selected} config={config} mobile={mobile} />;

      case "coc":
        return () => 
        <div  style={{width: '100%', height: '90vh', textAlign: 'center', overflowX: "hidden"}}><iframe style={{paddingLeft: mobile ? 0 : '25%', paddingRight: mobile ? 0:  '25%', height: '85vh', width:mobile ? '100%' : '50%', border: 'none'}} src={'./coc.html'} /></div>;

      default:
        break;
    }
  }

  return (
    <div >
      {
        loaded ?
            <div>
              <Menu>
              <ul>
                {
                  config.menu.map(m => {
                    return(
                      <li className={m.name === menuSelected ? 'active' : ''}><a onClick={() => setMenuSelected(m.name)}>{m.name}</a></li>
                    )
                  })
                }
              </ul> 
              </Menu>
              <div style={{ paddingTop: 50 }}>
                {
                  activeContent
                }
              </div>
              <Footer mobile={mobile} event_Selected={event_Selected} dangerouslySetInnerHTML={{ __html: config.footer }}/>

            </div>
          :
          <div>loading</div>
      }
    </div>
  );
}

const Footer = styled.div`
  position: ${props => props.event_Selected ? 'relative':'fixed'};
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 51px;
  margin-top: 20px;
  color: white;
  background-color: #333;
`;

const Menu = styled.div`
  z-index: 99999;

ul {
  z-index: 99;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  position: fixed;
  width: 100%;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #111;
}

.active {
  background-color: #4CAF50;
}

li {
  border-right: 1px solid #bbb;
}

li:last-child {
  border-right: none;
}
`;

export default App;
