import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function EventTemplate(props) {
    const [loaded, setloaded] = useState(false);
    const [width, setwidth] = useState(null);
    const [height, setheight] = useState(null);
    const [applynow, setapplynow] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [priceSelected, setPriceSelected] = useState('none');


    useEffect(() => {
        setwidth(window.innerWidth);
        setheight(window.innerHeight);

        if (width > 0) {
            setloaded(true);
        }

        if (width < 1000 && width != null) {
            setMobile(true)
        }
    })

    function title() {
        return (
            <div style={{ textAlign: 'center', margin: 20 }}>
                <h1 style={{fontSize: '3em'}} dangerouslySetInnerHTML={{ __html: props.event.title }}></h1>
                <h2 dangerouslySetInnerHTML={{ __html: props.event.sub_title }}></h2>
            </div>
        );
    }

    function cover() {
        return (
            <div style={{ textAlign: 'center', margin: mobile ? 5 : 20, height: mobile ? '100%' : width < 1370 ? '50vh' : '100%' }}>
                <a target="_blank" href={"/spec" + props.event.flyer} >
                    <img style={{ width: mobile ? '100%' : 'auto', height: mobile? '' : width < 1370 ? '50vh' : '100%' }} src={"/spec" + props.event.cover_image} />
                </a>
            </div>
        );
    }

    function apply() {
        return (
            <div style={{ textAlign: 'center', margin: 20, flex: 0.3, marginBottom: 50 }}>
                <img onClick={() => setapplynow(!applynow)} style={{ width: 200 }} src={require('../images/applynow.png')} />
                <div dangerouslySetInnerHTML={{ __html: props.event.registration_description }} />
                {
                    applynow ?

                        <Modal mobile={mobile} onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                setapplynow(false);
                            }
                        }}>
                            <ModalContent mobile={mobile}>
                                <CloseButton onClick={() => setapplynow(false)} aria-label="Close Apply Form">&times;</CloseButton>
                                <iframe src={props.event.registration_form} width="100%" height="800" border="0" ></iframe>
                            </ModalContent>
                        </Modal>
                        :
                        null
                }
            </div>
        );
    }

    function description() {
        return (
            <div style={{ textAlign: 'start', marginTop: 50, paddingLeft: mobile? 10 : 40 }}>
                <Icon src={require('../images/description_icon.png')} />
                <SectionTitle />
                <div style={{ padding: 20, paddingLeft: 40, textAlign: 'start', backgroundColor: '#ededed', marginRight: mobile ? 10 : 50 }} dangerouslySetInnerHTML={{ __html: props.event.description }} />
            </div>
        );
    }

    function price() {

        const onChange = (e) => {
            console.log(e.target.value);
            setPriceSelected(e.target.value);
            console.log(priceSelected);
        }

        return (
            <div style={{ textAlign: 'start', marginTop: 50, paddingLeft: mobile? 10 : 40 }}>
                <Icon src={require('../images/price_icon.png')} />
                <SectionTitle />
                <div style={{ padding: 20, backgroundColor: '#ededed', paddingLeft: 40, textAlign: 'start', marginRight: mobile? 10 : 50 }}>
                    {
                        props.event.cost != "free" ?
                            <div>
                                {
                                    props.event.pricing.length > 1 ?
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: props.event.price_description }} />
                                            <form style={{ marginTop: 30 }}>
                                                {
                                                    props.event.pricing.map(price => {
                                                        return (
                                                            <div className="radio">
                                                                <RadioButton className="container">
                                                                    <input type="radio" value={price.price}
                                                                        checked={priceSelected == price.price}
                                                                        onChange={(e) => onChange(e)} />
                                                                        <span className="checkmark" ></span>
                                                                    {
                                                                        price.specification
                                                                    }
                                                                </RadioButton>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </form>
                                            <div style={{ marginTop: 30, textAlign: 'center' }}>
                                                {
                                                    priceSelected != "none" ?
                                                    <div>
                                                    <h1 style={{width: 'auto'}}>{"Rs " + priceSelected}</h1>
                                                    <div>{props.event.cost_description}</div>
                                                    </div>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {
                                                props.event.pricing[0] + props.event.specification
                                            }
                                        </div>
                                }
                            </div>
                            :
                            <div>
                                  <h1>{props.event.cost}</h1>
                                  <div>{props.event.price_description}</div>
                            </div>
                    }
                </div>
            </div>
        );
    }

    function transmitter() {
        return (
            <div style={{ textAlign: 'start', marginTop: 50, paddingLeft: mobile? 10 : 40 }}>
                <Icon src={require('../images/teacher_icon.png')} />
                <SectionTitle />
                <div style={{ padding: 20, backgroundColor: '#ededed', display: 'flex', flexDirection: mobile? 'column' : 'row', paddingLeft: 40, marginRight: mobile? 10 : 50 }}>
                    <img style={{ height: 128, width: mobile ? 128 : 'auto' }} src={"/spec" + props.event.transmitter_logo} />
                    <div style={{ marginLeft: mobile ? 0 : 40, textAlign: "start" }}>
                        <h3 dangerouslySetInnerHTML={{ __html: props.event.transmitter }} />
                        <p dangerouslySetInnerHTML={{ __html: props.event.transmitter_description }} />
                        <a href={"mailto:" + props.event.transmitter_email}>{props.event.transmitter_email}</a>
                    </div>
                </div>
            </div>
        );
    }

    function location() {
        return (
            <div style={{ textAlign: 'start', marginTop: 50, paddingLeft:  mobile? 10 : 40 }}>
                <Icon src={require('../images/location_icon.png')} />
                <SectionTitle />
                <div style={{ padding: 20, backgroundColor: '#ededed', paddingLeft: 40, marginRight:  mobile? 10 : 50 }}>
                <div style={{display: 'flex', flexDirection: mobile? 'column' : 'row'}}>
                    <img style={{ height: 128, width: 128 }} src={"/spec" + props.event.location_logo} />
                    <div style={{ marginLeft: mobile ? 0 : 40, textAlign: "start" }}>
                        <h3 dangerouslySetInnerHTML={{ __html: props.event.location_name }} />
                        <p dangerouslySetInnerHTML={{ __html: props.event.location_address }} />
                    </div>

                </div>
                <iframe style={{ marginTop: 30 }} src={props.event.location_map} width="100%" height="300" border="0" ></iframe>
                </div>
            </div>
        );
    }

    function organisation() {
        return (
            <div style={{ textAlign: 'start', marginTop: 50, paddingLeft:  mobile? 10 : 40 }}>
                <Icon src={require('../images/organisation_icon.png')} />
                <SectionTitle />
                <div style={{ padding: 20, backgroundColor: '#ededed', marginRight: mobile? 10 : 50, paddingLeft: 40, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                    <h3 dangerouslySetInnerHTML={{ __html: props.event.organisation }} />
                    <img style={{ height: 128, width: 128 }} src={"/spec" + props.event.organisation_logo} />
                    <p dangerouslySetInnerHTML={{ __html: props.event.organisation_description }} />
                    <a style={{marginTop: 20}} target="_blank" href={props.event.organisation_link}>{props.event.organisation_link}</a>
                </div>
            </div>
        );
    }

    return (

        <Main_Container>
            {
                loaded ?
                    <div>
                        {
                            mobile ?
                                <div>
                                    {
                                        title()
                                    }
                                    {
                                        cover()
                                    }
                                    {
                                        apply()
                                    }
                                    {
                                        description()
                                    }
                                    {
                                        price()
                                    }

                                    {
                                        transmitter()
                                    }
                                    {
                                        location()
                                    }

                                    {
                                        organisation()
                                    }
                                </div>
                                :
                                <div>
                                    <Left_Container>
                                    {
                                        cover()
                                    }
                                    {
                                        apply()
                                    }
                                    </Left_Container>
                                    <Right_Container>
                                    {
                                        title()
                                    }
                                    {
                                        description()
                                    }
                                    {
                                        price()
                                    }

                                    {
                                        transmitter()
                                    }
                                    {
                                        location()
                                    }

                                    {
                                        organisation()
                                    }
                                    </Right_Container>
                                </div>
                        }


                    </div>
                    :
                    <div>loading</div>
            }
        </Main_Container>
    );
}
const Main_Container = styled.div`
    width: 100vw;
`;

const Left_Container = styled.div`
    width: 40vw;
    position: fixed;
    height: 60vh;
`;

const Right_Container = styled.div`
    margin-left: 40vw;
`;

const SectionTitle = styled.div`

`;

const Modal = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: ${props => props.mobile ? 0 : "100px"};
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    transition: all 0.3s linear;
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: auto;
    padding: ${props => props.mobile ? 0 : "23px"};
    padding-top: 23px;
    border-radius: 4px;
    max-width: ${props => props.mobile ? "100%" : "60%"};
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;

`;

const Icon = styled.img`
    width: 3em;
`;


const RadioButton = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

/* Hide the browser's default radio button */
& > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
& > span {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #FFF;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
& > :hover input ~ span {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
& > input:checked ~ span {
  background-color: #4CAF50;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
% > span:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
& > input:checked ~ span:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
`;
export default EventTemplate;