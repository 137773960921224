import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import EventContent from '../components/EventContent'
import EventTemplate from '../components/EventTemplate';

function Events(props) {

    const [events, setevents] = useState(null);
    const [eventSelected, seteventSelected] = useState(null);
    const [groups, setgroups] = useState([]);
    const [loaded, setloaded] = useState(false);
    const [selected, setSelected] = useState(false)


    useEffect(() => {
        fetch('./spec/events.json?version=pre01-20191210')
            .then((r) => r.json())
            .then((data) => setevents(data));
    }, [])

    useEffect(() => {
        if (events !== null) {
            get_Event_Groups();
        }
    }, [events])

    useEffect(() => {
        if (groups !== [] && events !== null) {
            setloaded(true);
        }
    }, [groups])

    const get_Event_Groups = async () => {
        let G = [];

        await events.events.map(e =>
            {
                if(G.length > 0){
                    G.map(g => {
                        if(g != e.group){
                            G.push(e.group)
                        }
                    })
                } else {
                    G.push(e.group)
                }
            }
        )

        setgroups(G);
    }

    const select_Event = (event) => {
        seteventSelected(event)


        if (event !== events.PageText.select) {
            setSelected(true);
            props.set_Selected(true);
        } else if (event === events.PageText.select) {
            setSelected(false);
            props.set_Selected(false);

        }
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            {
                loaded ?
                    <div>
                        <Select_Container mobile={props.mobile} selected={selected}>
                            <div style={{ margin: 20 }}>
                                <img style={{ objectFit: 'contain', height: selected ? 50 : props.mobile ? '30vh' : '60vh' }} src={props.config.logo} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                <div style={{ display: 'flex', flexDirection: props.mobile? "column" : "row", alignItems: 'center' }}>
                                    {
                                        events.event_type + " : "
                                    }
                                    <select style={{ margin: 20, fontSize: selected ? '' : props.mobile ? '0.35em' : '0.5em' }} onChange={(e) => select_Event(e.target.value)}>
                                        <option>
                                            {events.PageText.select}
                                        </option>
                                        {
                                            groups.map(g => {
                                                return (<optgroup label={g}>
                                                    {
                                                        events.events.map(e => {
                                                            if (e.group === g) {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.title}</option>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </optgroup>)
                                            })
                                        }
                                    </select>
                                </div>
                                {
                                    selected ?
                                        null
                                        :
                                        <div style={{ fontSize: '0.5em' }} dangerouslySetInnerHTML={{ __html: props.config.description }} />
                                }
                            </div>
                        </Select_Container>
                        <Content_Container>
                            {
                                eventSelected !== null ?
                                    events.events.map(e => {
                                        if (e.id === eventSelected) {
                                            return <EventTemplate event={e} />
                                        }
                                    })
                                    :
                                    null
                            }
                        </Content_Container>
                    </div>
                    :
                    <div>Loading</div>
            }
        </div>
    );
}


const Select_Container = styled.div`
display: flex;
flex-direction: ${window.innerWidth > 1000 ? 'row' : 'column'};
align-items: center;
justify-content: center;
text-align: center;
height: ${props => props.selected ? 'auto' : props.mobile ? '80vh' : '80vh'};
font-size: ${props => props.selected ? "" : "3em"};
`
const Content_Container = styled.div``;

export default Events;